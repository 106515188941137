import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import CryptoJS from "crypto-js"
import Config from '@src/configs/config.json'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// const existingAbility = [
//   {
//     action: 'manage',
//     subject: 'all'
//   }
// ]
let existingAbility = []
if (localStorage.getItem('auth')) {
  const bytes = CryptoJS.AES.decrypt(localStorage.getItem('auth'), Config.SECRET)
  const userdata = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

  if (userdata && userdata.role && userdata.role === 'child') {
    existingAbility = [{ action: 'manage', subject:'dashboard'}, { action: 'manage', subject: 'campaigns'}, { action: 'manage', subject: 'recipients'}, { action: 'manage', subject: 'profile'}]
  } else {
    existingAbility = [{ action: 'manage', subject:'dashboard'}, { action: 'manage', subject: 'campaigns'}, { action: 'manage', subject: 'recipients'}, { action: 'manage', subject: 'subusers'}, { action: 'manage', subject: 'profile'}]
  }
} else {
  existingAbility = [{ action: 'manage', subject: 'all' }]
}

export default new Ability(existingAbility || initialAbility)
